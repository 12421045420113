import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";

export const Seo = ({
	title,
	description,
	pathname,
	pageTitle,
	pageSubtitle,
	children,
}) => {
	const {
		title: defaultTitle,
		description: defaultDescription,
		siteUrl,
		company,
	} = useSiteMetadata();

	const imgUrl = pageTitle
		? `https://og.wemakeitspark.nl/api/og?title=${pageTitle}&subtitle=${pageSubtitle}`
		: `https://og.wemakeitspark.nl/api/og`;

	const seo = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		image: imgUrl,
		url: `${siteUrl}${pathname || ``}`,
	};

	return (
		<>
			<title>{seo.title}</title>
			<meta name="description" content={seo.description} />
			<meta name="image" content={seo.image} />
			<meta property="og:description" content={seo.description} />
			<meta property="og:url" content={seo.url} />
			<meta property="og:type" content="website" />
			<meta property="og:locale" content="nl_NL" />
			<meta property="og:site_name" content={company} />
			<meta
				name="google-site-verification"
				content="0nNK8cXRBCBSLQjujInme13DSulHv-P9d5kc8b_B3Ek"
			/>
			{children}
		</>
	);
};
