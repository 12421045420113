import * as React from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import Arrow from "../../images/arrow.svg";

const Button = ({
	children,
	className,
	buttonClassName,
	iconClassName,
	to,
	dark = false,
	flipArrow = false,
	hideArrow = false,
	...rest
}) => {
	const colorsButtonClass = dark
		? "text-gray border-gray"
		: "text-white border-white";

	const buttonClass = classnames(
		colorsButtonClass,
		"border px-4 h-9 flex items-center rounded-full uppercase font-normal tracking-wider text-sm transition-all select-none	cursor-pointer",
		buttonClassName
	);

	const colorsIconButtonClass = dark
		? "border-gray group-hover:bg-gray"
		: "border-white group-hover:bg-white";

	const iconButtonClass = classnames(
		colorsIconButtonClass,
		"h-9 border rounded-full aspect-square transition-all flex items-center justify-center ml-1 mr-1 text-center select-none",
		flipArrow && "rotate-180"
	);

	const colorsIconClass = dark
		? "fill-gray group-hover:fill-white"
		: "fill-white group-hover:fill-gray";

	const iconClass = classnames(
		colorsIconClass,
		"h-4 w-4 inline-block transition-all",
		iconClassName
	);

	return (
		<div className={className}>
			{to ? (
				<Link
					to={to}
					{...rest}
					className="group relative inline-flex items-center"
				>
					{!hideArrow && flipArrow && (
						<span className={iconButtonClass}>
							<Arrow className={iconClass} />
						</span>
					)}
					<span className={buttonClass}>{children}</span>
					{!hideArrow && !flipArrow && (
						<span className={iconButtonClass}>
							<Arrow className={iconClass} />
						</span>
					)}
				</Link>
			) : (
				<div
					className="group relative inline-flex items-center"
					{...rest}
				>
					<span className={buttonClass}>{children}</span>
					<span className={iconButtonClass}>
						<Arrow className={iconClass} />
					</span>
				</div>
			)}
		</div>
	);
};

export default Button;
