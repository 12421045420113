import { graphql, useStaticQuery } from "gatsby";
export const useSiteMetadata = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					company
					title
					social
					siteUrl
					phone
					linkedin
					instagram
					image
					facebook
					email
					description
				}
			}
		}
	`);

	return data.site.siteMetadata;
};
