import React from "react";
import Stamp from "../components/stamp";
import { useStaticQuery, graphql } from "gatsby";
import Button from "../components/button";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { Seo } from "../components/seo";

const BioPage = () => {
	const data = useStaticQuery(graphql`
		query {
			directus {
				links_in_bio {
					title
					url
				}
			}
		}
	`);

	return (
		<main className="bg-gray min-h-screen py-8 px-8">
			<div className="max-w-xl mx-auto">
				<Button to="/" flipArrow={true}>
					wemakeitspark.nl
				</Button>
				<div className="my-16">
					<Stamp className="w-24 h-24 fill-white mx-auto mb-8"></Stamp>
					<p className="text-white text-center">
						@wemakeitspark
						<CheckBadgeIcon className="w-5 h-5 text-cyan-400 inline-block align-sub ml-1"></CheckBadgeIcon>
					</p>
				</div>

				<div className="space-y-3">
					{data.directus.links_in_bio.map((item, i) => (
						<a
							href={item.url}
							className="border px-4 h-12 flex items-center rounded-full uppercase font-normal tracking-wider text-sm transition-all select-none cursor-pointer border-white hover:bg-white text-white hover:text-gray"
						>
							{item.title}
						</a>
					))}
				</div>
			</div>
		</main>
	);
};

export default BioPage;

export const Head = ({ location }) => (
	<Seo
		pathname={location.pathname}
		pageTitle="Bio"
		title="Bio - SPARK - fotografie voor cultuur, sport en entertainment"
		description="Link in Bio."
	>
		<meta name="robots" content="noindex" />
	</Seo>
);
